<template>
  <div class="page-invoices">
    <nav class="breadcrumb mt-5" aria-label="breadcrumbs">
      <ul>
        <li><router-link to="/dashboard">Dashboard</router-link></li>
        <li class="is-active"><router-link to="/dashboard/invoices" aria-current="true">Invoices</router-link></li>
      </ul>
    </nav>

    <div class="columns is-multiline"></div>

    <div class="columns is-multiline">
      <div class="column is-12">
        <h1 class="title">Invoices</h1>
      </div>

      <div class="column is-12 tabs is-toggle is-small mt-4">
        <ul>
          <li :class="{ 'is-active': localSelectedTab === 'All' }" @click="localSelectedTab = 'All'">
            <a>All</a>
          </li>
          <li :class="{ 'is-active': localSelectedTab === 'Validated' }" @click="localSelectedTab = 'Validated'">
            <a>Validated</a>
          </li>
          <li :class="{ 'is-active': localSelectedTab === 'Submission Failed' }" @click="localSelectedTab = 'Submission Failed'">
            <a>Failed</a>
          </li>
          <li :class="{ 'is-active': localSelectedTab === 'Pending Approval' }" @click="localSelectedTab = 'Pending Approval'">
            <a>Pending Approval</a>
          </li>
          <li :class="{ 'is-active': localSelectedTab === 'Approval Rejected' }" @click="localSelectedTab = 'Approval Rejected'">
            <a>Approval Rejected</a>
          </li>
          <li :class="{ 'is-active': localSelectedTab === 'User Cancelled' }" @click="localSelectedTab = 'User Cancelled'">
            <a>User Cancelled</a>
          </li>
        </ul>
      </div>

      <div class="columns" style="gap: 15px; margin-top: 10px; padding-left: 20px">
        <input v-model="filters.invoice_number" type="text" class="input is-rounded is-small" placeholder="Invoice Number">
        <input v-model="filters.tenant_account" type="text" class="input is-rounded is-small" placeholder="Account">
        <input v-model="filters.buyer_name" type="text" class="input is-rounded is-small" placeholder="Buyer Name">
        <input v-model="filters.store_code" type="text" class="input is-rounded is-small" placeholder="Store Code">
        <input v-model="filters.lhdn_uuid" type="text" class="input is-rounded is-small" placeholder="LHDN UUID">
      </div>

      <div class="columns" style="gap: 15px; margin-top: 10px; padding-left: 20px">
        <input v-model="filters.date_start" type="date" class="input is-rounded is-small" placeholder="Start Date" />
        <input v-model="filters.date_end" type="date" class="input is-rounded is-small" placeholder="End Date">
        
        <div class="select is-link is-small">
          <select v-model="filters.invoice_type">
            <option value="">All Invoice Types</option>
            <option v-for="type in uniqueInvoiceTypes" :key="type" :value="type">{{ type }}</option>
          </select>
        </div>

        <div class="select is-link is-small">
          <select v-model="filters.document_type">
            <option value="">All Document Types</option>
            <option v-for="type in uniqueDocumentTypes" :key="type" :value="type">{{ type }}</option>
          </select>
        </div>

        <div class="checkbox is-link is-small">
          <label class="checkbox">
            <input type="checkbox" v-model="filters.uiOnly">
            UI Only
          </label>
        </div>

        <button class="button is-primary is-rounded is-small" @click="applyFilters">Apply Filter</button>
        <button class="button is-info is-rounded is-small" 
                @click="downloadCSV" 
                :disabled="!isFilterApplied"  
                :title="!isFilterApplied ? 'At least 1 filter must be selected' : ''">CSV Report</button>
        <button class="button is-danger is-rounded is-small" @click="clearFilters">Reset Filters</button>
        <div class="select is-link is-small">
          <select v-model="itemsPerPage" @change="changePageSize">
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="200">200</option>
          </select>
        </div>
      </div>

      <div class="column is-12" v-if="loading">
        <div class="loader"></div>
      </div>

      <div class="column is-12" v-else>
        <table class="table is-fullwidth">
          <thead>
            <tr>
              <th @click="sortBy('tenant_account')" class="is-size-7">Account <button :class="getSortClass('tenant_account')"></button></th>
              <th @click="sortBy('sender.name')" class="is-size-7">Supplier Name <button :class="getSortClass('sender.name')"></button></th>
              <th @click="sortBy('sender_store_code')" class="is-size-7">Store Code <button :class="getSortClass('sender_store_code')"></button></th>
              <th @click="sortBy('customer.name')" class="is-size-7">Receiver Name <button :class="getSortClass('customer.name')"></button></th>
              <th @click="sortBy('invoice_type')" class="is-size-7">Doc Type <button :class="getSortClass('invoice_type')"></button></th>
              <th @click="sortBy('document_type')" class="is-size-7">Doc Label <button :class="getSortClass('document_type')"></button></th>
              <th @click="sortBy('invoice_number')" class="is-size-7">Doc Number <button :class="getSortClass('invoice_number')"></button></th>
              <th @click="sortBy('trading_date')" class="is-size-7">Trading Date <button :class="getSortClass('trading_date')"></button></th>
              <th @click="sortBy('invoice_date')" class="is-size-7">Issue Date <button :class="getSortClass('invoice_date')"></button></th>
              <th @click="sortBy('modified_at')" class="is-size-7">Submission Date <button :class="getSortClass('modified_at')"></button></th>
              <th @click="sortBy('total_payable_amount')" class="is-size-7">Document Total <button :class="getSortClass('total_payable_amount')"></button></th>
              <th @click="sortBy('status')" class="is-size-7">Status <button :class="getSortClass('status')"></button></th>
              <th @click="sortBy('source')" class="is-size-7">Source <button :class="getSortClass('source')"></button></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="invoice in paginatedInvoices" :key="invoice.uuid" @click="openQuickview(invoice)" class="invoice-row">
              <td style="text-align: left" class="is-size-7">{{ invoice.tenant_account }}</td>
              <td style="text-align: left" class="is-size-7">{{ invoice.sender && invoice.sender.name ? invoice.sender.name : '' }}</td>
              <td style="text-align: center" class="is-size-7">{{ invoice.sender_store_code }}</td>
              <td class="is-size-7">{{ invoice.customer.name }}</td>
              <td class="is-size-7">{{ invoice.invoice_type }}</td>
              <td style="text-align: center" class="is-size-7">{{ invoice.document_type }}</td>
              <td class="is-size-7">{{ invoice.invoice_number }}</td>
              <td class="is-size-7">{{ invoice.trading_date }}</td>
              <td class="is-size-7">{{ invoice.invoice_date }}</td>
              <td style="text-align: center" class="is-size-7">{{ formatDate(invoice.created_at) }}</td>
              <td style="text-align: right" class="is-size-7">{{ invoice.invoice_currency + " " + formatCurrency(invoice.total_payable_amount) }}</td>
              <td style="text-align: left" class="is-size-7">
                <a @click.stop="showDetails(invoice)">{{ invoice.status }}</a>
              </td>
              <td class="is-size-7">{{ invoice.source }}</td>
            </tr>
          </tbody>
        </table>
        <div class="pagination">
          <button @click="prevPage" :disabled="currentPage === 1" class="button is-ghost is-small">Previous</button>
          <span class="strong is-size-7"> {{ currentPage*itemsPerPage }} of {{ totalDocuments }} Documents</span>
          <button @click="nextPage" :disabled="currentPage === totalPages" class="button is-ghost is-small">Next</button>
        </div>
      </div>
    </div>

    <!-- Quickview Modal for invoice -->
    <QuickviewComponent v-if="selectedInvoice" :invoice="selectedInvoice" @close="closeQuickview" @edit="editInvoice" @cancel="cancelInvoice" ref="quickviewComponent" />

    <!-- Modal for submission details -->
    <div class="modal" v-if="isModalActive">
      <div class="modal-background" @click="closeModal"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Submission Details</p>
          <button class="delete" aria-label="close" @click="closeModal"></button>
        </header>
        <section class="modal-card-body">
          <p>{{ modalContent }}</p>
        </section>
        <footer class="modal-card-foot">
          <button class="button" @click="closeModal">Close</button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import QuickviewComponent from '@/components/QuickView.vue';

export default {
  name: 'ViewInvoices',
  components: {
    QuickviewComponent
  },
  props: {
    selectedTab: {
      type: String,
      default: 'All'
    }
  },
  data() {
    return {
      invoices: [],
      filters: {
        date_start: '',
        date_end: '',
        buyer_name: '',
        tenant_account: '',
        invoice_type: '',
        document_type: '',
        invoice_number: '',
        store_code: '',
        lhdn_uuid: '',
        uiOnly: false,
      },
      uniqueInvoiceTypes: [], // Moved to data from computed
      uniqueDocumentTypes: [], // Moved to data from computed
      localSelectedTab: this.$route.query.tab || 'All',
      itemsPerPage: 50,
      currentPage: 1,
      totalPages: 1,
      totalDocuments: '',
      isModalActive: false,      // Controls the modal visibility
      modalContent: '',          // Content for the modal
      sortKey: '',
      sortOrders: {
        tenant_account: 1,
        'sender.name': 1,
        sender_store_code: 1,
        invoice_type: 1,
        document_type: 1,
        invoice_number: 1,
        invoice_date: 1,
        trading_date: 1,
        modified_at: 1,
        'customer.name': 1,
        total_payable_amount: 1,
        status: 1,
        source: 1
      },
      selectedInvoice: null,     // Selected invoice for Quickview
      loading: true
    };
  },
  watch: {
    localSelectedTab() {
      this.applyFilters();
    }
  },
  mounted() {
    this.getInvoices();
    this.getUniqueTypes();
  },
  computed: {
    sortedInvoices() {
      return this.invoices.slice().sort((a, b) => {
        let keyA = this.getKey(a, this.sortKey);
        let keyB = this.getKey(b, this.sortKey);
        if (keyA < keyB) return -1 * this.sortOrders[this.sortKey];
        if (keyA > keyB) return 1 * this.sortOrders[this.sortKey];
        return 0;
      });
    },
    paginatedInvoices() {
      return this.invoices;
    },
    isFilterApplied() {
      return (
        this.filters.date_start ||
        this.filters.date_end ||
        this.filters.buyer_name ||
        this.filters.tenant_account || // Updated
        this.filters.invoice_number ||
        this.filters.store_code ||
        this.filters.invoice_type || 
        this.filters.lhdn_uuid  ||
        this.filters.document_type // New filter check
      );
    }
  },
  methods: {
    getKey(obj, path) {
      return path.split('.').reduce((acc, part) => acc && acc[part], obj);
    },
    sortBy(key) {
      if (this.sortKey === key) {
        this.sortOrders[key] = this.sortOrders[key] * -1;
      } else {
        this.sortKey = key;
        this.sortOrders[key] = 1;
      }
    },
    getSortClass(key) {
      if (this.sortKey !== key) {
        return '';
      }
      return this.sortOrders[key] === 1 ? 'sort-asc' : 'sort-desc';
    },
    applyFilters() {
      this.currentPage = 1;
      this.getInvoices();
    },
    clearFilters() {
      this.filters = {
        date_start: '',
        date_end: '',
        buyer_name: '',
        tenant_account: '',
        invoice_type: '',
        document_type: '',
        invoice_number: '',
        store_code: '',
        lhdn_uuid: '',
        uiOnly: false,
      };
      this.localSelectedTab = 'All';  // Reset tab selection if needed
      this.applyFilters();  // Re-fetch the data with cleared filters
    },
    getUniqueTypes() {
      axios
        .get('/api/v1/unique-types/')
        .then(response => {
          this.uniqueInvoiceTypes = response.data.invoice_types;
          this.uniqueDocumentTypes = response.data.document_types;
        })
        .catch(error => {
          console.error('Error fetching unique types:', error);
        });
    },
    getInvoices() {
      this.loading = true; // Start loading
      const params = new URLSearchParams();
      if (this.filters.date_start) params.append('dateStart', this.filters.date_start);
      if (this.filters.date_end) params.append('dateEnd', this.filters.date_end);
      if (this.filters.buyer_name) params.append('buyer_name', this.filters.buyer_name);
      if (this.filters.tenant_account) params.append('tenant_account', this.filters.tenant_account); // Updated
      if (this.filters.invoice_number) params.append('invoice_number', this.filters.invoice_number);
      if (this.filters.store_code) params.append('store_code', this.filters.store_code);
      if (this.filters.invoice_type) params.append('invoice_type', this.filters.invoice_type); // New filter
      if (this.filters.document_type) params.append('document_type', this.filters.document_type);
      if (this.filters.lhdn_uuid) params.append('lhdn_uuid', this.filters.lhdn_uuid);
      if (this.filters.uiOnly) {params.append('source', 'UI')}
      params.append('page', this.currentPage);
      params.append('page_size', this.itemsPerPage);

      if (this.localSelectedTab !== 'All') {
        params.append('status', this.localSelectedTab);
      }

      axios
        .get('/api/v1/summary-invoices/', { params })
        .then(response => {
          this.invoices = response.data.results;
          this.totalDocuments = response.data.count;
          this.totalPages = Math.ceil(response.data.count / this.itemsPerPage);
          this.loading = false; // End loading
        })
        .catch(error => {
          console.error('Error fetching invoices:', error);
          this.invoices = []; // Ensure this.invoices is an array in case of error
          this.loading = false; // End loading
        });
    },
    showDetails(invoice) {
      this.modalContent = invoice.status_message;
      this.isModalActive = true;    // Open modal
    },
    closeModal() {
      this.isModalActive = false;   // Close modal
    },
    formatCurrency(value) {
      if (!value) return '';
      return parseFloat(value).toLocaleString('en-US', {
        style: 'decimal',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
      });
    },
    formatCSVValue(value) {
      if (!value) return '';
      if (value.includes(',') || value.includes('\n')) {
        return `"${value}"`;
      }
      return value;
    },
    downloadCSV() {
      if (!this.isFilterApplied) return;

      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += "Account,Store Code, Sender Name,Receiver Name,Doc Type,Doc Label,Doc Number,Issue Date,Document Currency,Document Total,Status,LHDN_UUID\n"; // Updated CSV Header
      this.invoices.forEach(invoice => {
        let lhdnUUID = invoice.lhdn_validation ? invoice.lhdn_validation.lhdn_uuid : '';
        let account = invoice.tenant_account || '';
        let row = `${this.formatCSVValue(account)},${this.formatCSVValue(invoice.sender_store_code)},${this.formatCSVValue(invoice.sender.name)},${this.formatCSVValue(invoice.customer.name)},${this.formatCSVValue(invoice.invoice_type)},${this.formatCSVValue(invoice.document_type)},${this.formatCSVValue(invoice.invoice_number)},${this.formatCSVValue(invoice.invoice_date)},${this.formatCSVValue(invoice.invoice_currency)},${this.formatCSVValue(invoice.total_payable_amount)},${this.formatCSVValue(invoice.status)},${this.formatCSVValue(lhdnUUID)}`;
        csvContent += row + "\n";
      });

      const date = new Date();
      const dateString = `${date.getFullYear()}${(date.getMonth() + 1).toString().padStart(2, '0')}${date.getDate().toString().padStart(2, '0')}_${date.getHours().toString().padStart(2, '0')}${date.getMinutes().toString().padStart(2, '0')}${date.getSeconds().toString().padStart(2, '0')}`;

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `invoices_${dateString}.csv`);
      document.body.appendChild(link); // Required for FF

      link.click(); // This will download the file
      document.body.removeChild(link); // Clean up
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toISOString().split('T')[0];
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.getInvoices();
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.getInvoices();
      }
    },
    changePageSize() {
      this.currentPage = 1;
      this.getInvoices();
    },
    editInvoice(invoice) {
      this.$router.push({ name: 'EditInvoice', params: { uuid: invoice.uuid } });
    },
    openQuickview(invoice) {
      this.selectedInvoice = invoice;
      this.$nextTick(() => {
        this.$refs.quickviewComponent.$el.classList.add('is-active');
      });
    },
    closeQuickview() {
      this.selectedInvoice = null;
      if (this.$refs.quickviewComponent && this.$refs.quickviewComponent.$el) {
        this.$refs.quickviewComponent.$el.classList.remove('is-active');
      }
    },
    cancelInvoice(invoice) {
      axios.post(`/api/v1/invoices/${invoice.uuid}/cancel/`)
        .then(() => {
          this.getInvoices();
          this.closeQuickview();
        })
        .catch(error => {
          console.error('Error cancelling invoice:', error);
        });
    }
  }
}
</script>

<style>
.center-align {
  text-align: center;
}

.right-align {
  text-align: right;
}

.sort-asc::after {
  content: ' ▲';
}

.sort-desc::after {
  content: ' ▼';
}

th {
  cursor: pointer;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  margin: 0 10px;
  padding: 5px 10px;
}

.invoice-row:hover {
  background-color: lightgrey;
  cursor: pointer;
}

.quickview {
  position: fixed;
  top: 0;
  right: -400px;
  width: 400px;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0,0,0,0.1);
  transition: right 0.3s ease;
  z-index: 1000;
}
.quickview.is-active {
  right: 0;
}

.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #3498db;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
  margin: 50px auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
