<template>
  <div class="approve-invoice-page mt-5">
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li><router-link to="/dashboard">Dashboard</router-link></li>
        <li class="is-active"><router-link to="/dashboard/invoices/approvals" aria-current="true">Approvals</router-link></li>
      </ul>
    </nav>
    <h2>Pending Invoices</h2>
    <div class="column is-12">
      <table class="table is-fullwidth">
        <thead>
          <tr>
            <th class="is-size-7">Sender Name</th>
            <th class="is-size-7">Doc Type</th>
            <th class="is-size-7">Doc Number</th>
            <th class="is-size-7">Issue Date</th>
            <th class="is-size-7">Receiver Name</th>
            <th class="is-size-7">Document Total</th>
            <th class="is-size-7">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="invoice in pendingInvoices" :key="invoice.id">
            <td class="is-vcentered is-size-7">{{ invoice.supplier_name }}</td>
            <td class="is-vcentered is-size-7">{{ invoice.invoice_type }}</td>
            <td class="is-vcentered is-size-7">{{ invoice.invoice_number }}</td>
            <td class="is-vcentered is-size-7">{{ invoice.invoice_date }}</td>
            <td class="is-vcentered is-size-7">{{ invoice.customer.name }}</td>
            <td class="is-vcentered is-size-7">{{ formatCurrency(invoice.total_net_amount) }}</td>
            <td>
              <div>
                <span class="icon-container" @click="previewInvoice(invoice)" title="Preview Invoice">
                  <font-awesome-icon icon="fa-eye" class="has-text-success"/>
                </span>
                <span v-if="invoice.created_by !== currentUserId" class="icon-container ml-4" @click="confirmChangeStatus(invoice.uuid, 'Approved')" title="Approve Invoice">
                  <font-awesome-icon icon="check" class="has-text-warning"/>
                </span>
                <span v-if="invoice.created_by !== currentUserId" class="icon-container ml-4" @click="openRejectionModal(invoice.uuid)" title="Reject Invoice">
                  <font-awesome-icon icon="times" class="has-text-danger"/>
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Invoice Preview Modal -->
    <InvoicePreview v-if="showPreview" :invoice="selectedInvoice" :customer="selectedCustomer" @close="closePreviewModal" />

    <!-- Rejection Modal -->
    <div class="modal" v-if="showRejectionModal">
      <div class="modal-background" @click="closeRejectionModal"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Rejection Reason</p>
          <button class="delete" aria-label="close" @click="closeRejectionModal"></button>
        </header>
        <section class="modal-card-body">
          <textarea class="textarea" v-model="rejectionReason" placeholder="Enter the reason for rejection"></textarea>
        </section>
        <footer class="buttons modal-card-foot">
          <button class="button is-danger" @click="rejectInvoice">Reject</button>
          <button class="button" @click="closeRejectionModal">Cancel</button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { toast } from 'bulma-toast';
import InvoicePreview from '@/components/CreateInvoice/InvoicePreview.vue';

export default {
  name: 'InvoiceApproval',
  components: {
    InvoicePreview
  },
  data() {
    return {
      pendingInvoices: [],
      showPreview: false,           // Modal for invoice preview
      showRejectionModal: false,    // Modal for rejection
      selectedInvoice: null,        // Currently selected invoice for preview
      selectedCustomer: null,       // Customer for selected invoice
      currentUserId: null,          // User ID for current session
      rejectionReason: '',          // Reason for rejecting invoice
      invoiceToReject: null         // Invoice to be rejected
    };
  },
  created() {
    this.fetchCurrentUserId();
    this.fetchPendingInvoices();
  },
  methods: {
    fetchCurrentUserId() {
      const userId = localStorage.getItem('userid');
      this.currentUserId = parseInt(userId, 10);
    },
    fetchPendingInvoices() {
      axios.get('/api/v1/invoices/pending/')
        .then(response => {
          this.pendingInvoices = response.data.results;
        })
        .catch(error => {
          console.error('There was an error fetching the pending invoices!', error);
          toast({
            message: 'Error fetching pending invoices!',
            type: 'is-danger',
            dismissible: true,
            pauseOnHover: true,
            duration: 5000,
            position: 'top-right'
          });
        });
    },
    openRejectionModal(uuid) {
      this.invoiceToReject = uuid;
      this.showRejectionModal = true;   // Show the rejection modal
    },
    closeRejectionModal() {
      this.showRejectionModal = false;  // Close the rejection modal
      this.rejectionReason = '';
      this.invoiceToReject = null;
    },
    rejectInvoice() {
      this.confirmChangeStatus(this.invoiceToReject, 'Rejected', this.rejectionReason);
      this.closeRejectionModal();
    },
    confirmChangeStatus(invoiceUuid, status, rejectionReason = null) {
      const confirmationMessage = `Are you sure you want to mark this invoice ${status.toLowerCase()}? This action is not reversible.`;
      if (window.confirm(confirmationMessage)) {
        this.changeInvoiceStatus(invoiceUuid, status, rejectionReason);
      }
    },
    changeInvoiceStatus(invoiceUuid, status, rejectionReason = null) {
      axios.post(`/api/approve-invoice/${invoiceUuid}/`, { status: status, rejection_reason: rejectionReason }, {
        withCredentials: true
      })
      .then(() => {
        this.fetchPendingInvoices();
        toast({
          message: `Invoice ${status.toLowerCase()} successfully!`,
          type: 'is-success',
          dismissible: true,
          pauseOnHover: true,
          duration: 5000,
          position: 'top-right'
        });
      })
      .catch(error => {
        console.error(`There was an error ${status.toLowerCase()}ing the invoice!`, error);
        toast({
          message: `Error ${status.toLowerCase()}ing invoice!`,
          type: 'is-danger',
          dismissible: true,
          pauseOnHover: true,
          duration: 5000,
          position: 'top-right'
        });
      });
    },
    previewInvoice(invoice) {
      this.selectedInvoice = invoice;
      this.selectedCustomer = invoice.customer;
      this.showPreview = true;  // Show the preview modal when an invoice is clicked
    },
    closePreviewModal() {
      this.showPreview = false;  // Close the preview modal
      this.selectedInvoice = null;
      this.selectedCustomer = null;
    },
    formatCurrency(amount) {
      if (!amount) return '';
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'MYR' }).format(amount);
    }
  }
};
</script>

<style>
.table th, .table td {
  text-align: center;
}
.icon-container {
  cursor: pointer;
}
</style>
